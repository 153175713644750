import { Api as backendApis } from '../swagger/backend'
import { Api as thirdPartyApis } from '../swagger/thirdParty'
import { Api as purhcaseApis } from '../swagger/purchase'
import Bottleneck from 'bottleneck'
import { store } from 'index'

const limiter = new Bottleneck({
  maxConcurrent: 3,
  minTime: 333,
})

const dev = 'dev'
const baseUrl = 'https://cutrack-backend-byfkup2cdq-de.a.run.app' //prod
// const baseUrl = 'https://cutrack-backend-crm-restful-byfkup2cdq-de.a.run.app' //dev

const customFetch = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
  // 正常執行 REQUEST
  const response = await fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: store.getState().user.userInfo.authorizationToken,
      // 'Grpc-Metadata-Csrf-Token':
      //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJtX2lkIjoiOGI3MGQ4MDAtYzVmNS00Y2FhLTkyOGMtMWIwN2Q0ZDIxNGUzIiwidXNlcl9pZCI6IjhiNzBkODAwLWM1ZjUtNGNhYS05MjhjLTFiMDdkNGQyMTRlMyIsImF1ZCI6IjhiNzBkODAwLWM1ZjUtNGNhYS05MjhjLTFiMDdkNGQyMTRlMyIsImV4cCI6MTY4NDIwNDAyNiwiaWF0IjoxNjg0MTMyMDI2LCJpc3MiOiJyYW1hdGV0ZWNoIn0.INtGp6UacRoyJMk0g-lJoyyqCiUllycGs9ysT8UkKIw',
      'Grpc-Metadata-Csrf-Token': store.getState().user.csrf,
      'Grpc-Metadata-Firm_Id': store.getState().user.firmInfo.firmId,
      'Grpc-Metadata-User_Id': store.getState().user.userInfo.userId,
    },
  })

  const responseClone = response.clone()
  const json = await responseClone.json()
  if (json?.code === 16) {
    // CSRF 過期，重新登入取得新的 CSRF
    try {
      const csrfResponse = await teddyApi.firmAutoLogin.cutrackFirmAutoLogin({
        firmId: store.getState().user.firmInfo.firmId,
        userId: store.getState().user.userInfo.userId,
        token: store.getState().user.userInfo.token,
        deviceId: store.getState().user.deviceId,
      })
      const newCsrf = csrfResponse.headers.get('Grpc-Metadata-Csrf-Token')
      // 取得了新的CSRF
      store.dispatch({
        type: 'FIRM_LOGIN',
        payload: {
          ...csrfResponse.data,
          deviceId: store.getState().user.deviceId,
          csrf: newCsrf,
        },
      })

      // 重新設定 headers
      const newHeaders = {
        ...init?.headers,
        'Grpc-Metadata-Csrf-Token': newCsrf || '',
      }
      const newInit = {
        ...init,
        headers: newHeaders,
      }

      // 重新跑一次原本的 REQUEST
      return await customFetch(input, newInit)
    } catch (err) {
      // 重新登入失敗，清除登入資訊
      if (err.error.code === 3) {
        store.dispatch({ type: 'FIRM_LOGOUT' })
      }
      return err
    }
  } else if (json?.code === 12) {
    store.dispatch({ type: 'FIRM_LOGOUT' })
    return response
  } else {
    return response
  }
}

export const teddyApi = new backendApis({
  baseUrl: baseUrl,
  customFetch(input: RequestInfo | URL, init: RequestInit) {
    return limiter.schedule(() => customFetch(input, init))
  },
  // customFetch(input, init) {
  //   return limiter.schedule(() => fetch(input, { ...init, headers: { authorization: store.getState().user.userInfo.authorizationToken } }))
  // },
})

export const sherryApi = new thirdPartyApis({
  baseUrl:
    dev === 'prod' ? 'https://cutrack-liveroom-byfkup2cdq-de.a.run.app' : 'https://cutrack-liveroom-crm-restful-byfkup2cdq-de.a.run.app',
  customFetch(input: RequestInfo | URL, init: RequestInit) {
    return limiter.schedule(() => customFetch(input, init))
  },
  // customFetch: customFetch,
})

export const purchaseApi = new purhcaseApis({
  baseUrl:
    dev === 'prod'
      ? 'https://ramate-crm-third-party-gmt4o4nvya-de.a.run.app'
      : 'https://ramate-crm-third-party-beta-gmt4o4nvya-de.a.run.app',
  customFetch(input: string | Request | URL, init: RequestInit) {
    return limiter.schedule(() => fetch(input, { ...init }))
  },
})
