import React from 'react'
import DropDown from 'components/Elements/Dropdown'

import { AiTwotoneShop, AiOutlineRight, AiFillSetting } from 'react-icons/ai'
import { RiLiveFill, RiBarChartBoxFill } from 'react-icons/ri'
import { HiOutlinePaperClip } from 'react-icons/hi'
import { BsFillChatSquareTextFill } from 'react-icons/bs'
import { FaPhotoVideo, FaUsers, FaBookDead, FaMailBulk } from 'react-icons/fa'
import { FiMoreVertical } from 'react-icons/fi'
import { IoIosMail } from 'react-icons/io'
import { GiMeepleGroup } from 'react-icons/gi'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CrmLogo from './crm.svg'
import packageJson from '../../../package.json'

import * as S from './styles'

const Sidebar = () => {
  const isMenuCollapsed = useSelector((state) => state.settings.isMenuCollapsed)
  const { bombmyLive, bombmySignup, bombmySetting } = useSelector((state) => state.user.bombmyAuthority)
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()

  const linkArray = [
    {
      label: '直播分析',
      icon: <RiBarChartBoxFill />,
      to: '/dashboard',
      // notification: 4,
      type: 'stream',
    },
    {
      label: '我的影片',
      icon: <FaPhotoVideo />,
      to: '/manage-live',
      // notification: 4,
      type: 'stream',
    },
    {
      label: '我的商品',
      icon: <AiTwotoneShop />,
      to: '/manage-product',
      notification: 0,
      type: 'stream',
    },
    {
      label: '管理報名',
      icon: <HiOutlinePaperClip />,
      to: '/manage-form',
      notification: 0,

      type: 'form',
    },
    {
      label: '管理直播',
      icon: <RiLiveFill />,
      to: '/manage-live-stream',
      notification: 0,
      type: 'stream',
    },
    // {
    //   label: '管理訂單',
    //   icon: <HiClipboardList />,
    //   to: '/manage-orders',
    //   notification: 0,
    // },
    {
      label: '留言組',
      icon: <BsFillChatSquareTextFill />,
      to: '/manage-msgroup',
      notification: 0,
      type: 'stream',
    },
    {
      label: '虛擬使用者',
      icon: <FaUsers />,
      to: '/manage-virtual-user',
      notification: 0,
      type: 'stream',
    },
    {
      label: '寄送簡訊、信箱',
      icon: <IoIosMail />,
      to: '/manage-sms-email',
      notification: 0,
      type: 'form',
    },
    {
      label: '信件模板',
      icon: <FaMailBulk />,
      to: '/manage-mail-template',
      notification: 0,
      type: 'form',
    },
    {
      label: '黑名單',
      icon: <FaBookDead />,
      to: '/manage-blacklist',
      notification: 0,
      type: 'stream',
    },
    {
      label: '廠商設定',
      icon: <AiFillSetting />,
      to: '/brand-setting',
      notification: 0,
      type: 'setting',
    },
    {
      label: '聯盟行銷',
      icon: <GiMeepleGroup />,
      to: '/association-setting',
      notification: 0,
      type: 'association',
    },
    {
      label: 'CRM平台',
      icon: <img src={CrmLogo} width="20px" />,
      to: { pathname: 'https://crm.ramatetech.com/auth-pages/login' },
      notification: 0,
      type: 'crm',
    },
  ]

  function handleLogout() {
    dispatch({ type: 'FIRM_LOGOUT', payload: null })
    history.push('/auth/login')
  }

  return (
    <S.Sidebar isOpen={isMenuCollapsed}>
      <>
        <S.SidebarButton isOpen={isMenuCollapsed} onClick={() => dispatch({ type: 'SET_MENU_COLLAPSED', payload: !isMenuCollapsed })}>
          <AiOutlineRight />
        </S.SidebarButton>
      </>
      <S.Logo onClick={() => history.push('/')}>
        {/* "resources/images/Logo/CuTrack_logo.png" */}
        {isMenuCollapsed ? (
          <img src="/resources/images/logos/logo_banner.webp" width={'100%'} height={'100%'} alt="logo" />
        ) : (
          // <S.LogoLabel>
          //   BombMy
          //   <br />
          //   直播管理平台
          // </S.LogoLabel>
          <img src="/resources/images/logos/logo512_big.webp" alt="logo" />
        )}
      </S.Logo>
      <S.Divider />
      <S.UserLabel>
        <S.Avatar>
          <img src={user.firmInfo.imgUrl} />
        </S.Avatar>
        {isMenuCollapsed && (
          <>
            {user.firmInfo.name}

            <DropDown
              options={[
                <S.MoreItem key="1" onClick={() => handleLogout()}>
                  登出
                </S.MoreItem>,
              ]}
              icon={<FiMoreVertical />}
            />
          </>
        )}
      </S.UserLabel>
      <S.Divider />
      <S.LinkScroll>
        <div>
          {linkArray.map(({ icon, label, notification, to, type }) => (
            <div key={label}>
              {(type === 'form' && bombmySignup !== 0) ||
              (type === 'crm' && bombmyLive !== 0) ||
              (type === 'stream' && bombmyLive !== 0) ||
              (type === 'association' && bombmyLive !== 0) ||
              (type === 'setting' && bombmySetting !== 0) ? (
                <S.LinkContainer key={label} isActive={pathname === to}>
                  <S.StyledLink to={to} target={type === 'crm' ? '_blank' : null} style={!isMenuCollapsed ? { width: `fit-content` } : {}}>
                    <S.LinkIcon>{icon}</S.LinkIcon>
                    {isMenuCollapsed && (
                      <>
                        <S.LinkLabel>{label}</S.LinkLabel>
                        {!!notification && <S.LinkNotification>{notification}</S.LinkNotification>}
                      </>
                    )}
                  </S.StyledLink>
                </S.LinkContainer>
              ) : null}
            </div>
          ))}
        </div>
        <div>© 2023 根垣科技 -v{packageJson.version}</div>
      </S.LinkScroll>
      {/* <S.Divider />
      <S.Theme>
        {isMenuCollapsed && <S.ThemeLabel>深夜模式</S.ThemeLabel>}
        <S.ThemeToggler
          isActive={theme === 'dark'}
          onClick={() =>
            setTheme((theme) => (theme === 'light' ? 'dark' : 'light'))
          }
        >
          <S.ToggleThumb style={theme === 'dark' ? { right: '1px' } : {}} />
        </S.ThemeToggler>
      </S.Theme> */}
    </S.Sidebar>
  )
}

export default Sidebar
